* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --am-d-green: #00352F;
  --am-m-green: #00594F;
  --am-l-green: #cedc00;
}

body {
  /*background-color: var(--am-m-green);*/
  background-color: #00352F;
  color: white;
}

.header {
  display: flex;
  border-bottom: 1px solid var(--am-l-green);
  padding: 1.5rem;
  justify-content: space-around;
  min-height: 10vh;
  background-color: var(--am-m-green);
}

a {
  color: var(--am-l-green);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: var(--am-l-green);
}

h1 {
  text-align: center;
  font-size: 1.5rem;
  margin: 2rem;
}

h2 {
  text-align: center;
  font-size: 1.3rem;
  margin: 1.5rem;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.main {
  min-height: 80vh;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  gap: 2rem;
}

.clickable:hover {
  cursor: pointer;
}

.flex-container-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}

.flex-container-col-v2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

div.gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

div.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

/* .content {
  
}
 */

 .info-icon {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.info-icon span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #cedc00;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  border-radius: 50%;
}

.info-content {
  position: absolute;
  width: 250px;
  top: 25px; /* Változtathatod a távolságot az ikontól */
  left: 0;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  display: none;
  border-radius: 10px;
  color: black;
  z-index: 2;
}

div.thumbnailWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
}

div.thumbnailWrapper > div {
  display: flex;
  align-items: flex-start;
  gap: 0.7rem;
}

div.picButtons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.7rem;
}

div.error {
  margin: 1rem 0;
  font-size: 1.1rem;
  color: red;
}

div.imageUpload {
  margin: 1rem 0;
  border: 1px solid #cedc00;
  border-radius: 10px;
  padding: .8rem;
}

.info-icon:hover .info-content {
  display: block;
}
 
div.updateMsg {
  margin: 1rem 0;
  padding: 1rem;
  background-color: gainsboro;
  color: red;
  border-radius: 10px;
}

div.newsletterForm {
  max-width: 500px;
  margin: 0 auto;
}

.appealForm {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: .5rem;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.appealForm input {
  width: 250px;
  height: 50px;
  padding: .5rem;
  margin: 0 auto;
  background-color: #cedc00;
  border: 1px solid var(--am-m-green);
  border-radius: 5px;
}

.appealForm textarea {
  background-color: #cedc00;
  border: 1px solid var(--am-m-green);
  border-radius: 5px;
  padding: .5rem;
}

.sidebar {
  background-color: var(--am-m-green);
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
}

.saleSettings {
  margin: 1.5rem 0;
  padding: 1.5rem;
  border-radius: 10px;
  background-color: #00594F;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 10vh;
  border-top: 1px solid var(--am-l-green);
  background-color: var(--am-m-green);
}

.footerText {
  margin-bottom: .5rem;
  font-size: .9rem;
}

.loginWrapper {
  width: 20rem;
  margin: 30vh auto;
  text-align: center;
  /* border: 1px solid wheat; */
}

.loginResponse {
  margin: 1rem;
  color: red;
}

.sale-info {
  display: grid;
  font-size: .9rem;
}

.sale-perc {
  background-color: crimson;
  width: fit-content;
  padding: .2rem .5rem;
}

.availables-filter {
  margin: 1rem;
}

.availables-filter label {
  margin-left: .5rem;
}

.sending-info {
  margin: 1rem;
}

.in-stock, 
.available {
  color: greenyellow;
  font-size: .9rem;
}

.no-stock,
.non-available {
  color: red;
  font-size: .9rem;
}

.product-name-link {
  font-size: 1.3rem;
  color: white;
}

.cust-info-wrapper {
  border: 1px solid lime;
  padding: 1rem;
  display: grid;
  grid-template-areas: 
    "name email tel"
    "del-type post-address post-number"
    "bill-name tax-number bill-address";
  gap: 1rem;
}

.data-group {
  display: grid;
  gap: .2rem;
}

.data-group label {
  color: gray;
}

.from-store-quantity {
  font-size: .9rem;
  color: var(--am-l-green);
}

.subcats-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.subcat {
  font-size: 1.1rem;
  color: #cedc00;
}

.new-subcat {
  max-width: fit-content;
}

.delete-icon {
  color: crimson;
}

.delete-icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  
  .loginWrapper {
    margin: 10vh auto;
  }

}


